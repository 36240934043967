import { Component,Input, OnInit, Output,OnChanges,SimpleChanges, EventEmitter } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AuthJwtService } from 'src/app/core/auth-jwt.service';
import { Principal } from 'src/app/core/principal.service';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit,OnChanges{

  activeRoot ='UrbanLocalBodies';
  years: Array<{}> = [];
  districts: Array<{}> = [];
  year: number;
  minDate: Date;
  maxDate: Date;
  currentPath: string;
  filters =
  { 
    lsDistrict: "0", 
    lsfirm: "0",
    lsFromDate: "0", 
    lsToDate: "0",
    lsScheme: "0", 
    lsSector: "0", 
    lsStatus: "0", 
    lsYear: "2018", 
    lsOfficeCat: "0" ,
    isClear: false
  };

  @Output() selectedFilter = new EventEmitter<object>();
  userRole: any;

  constructor(
    private shared : SharedService,
    private principal: Principal,
    private auth: AuthJwtService,
  ) {}

  ngOnChanges(change : SimpleChanges){
  }
  ngOnInit() {
    this.shared.currentMessage.subscribe(res => {
      this.userRole = res['userRole'];
    });
  }
  logout() {
    this.principal.authenticate(null);
    this.auth.logout();
  }
}
