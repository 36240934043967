import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, Subject , BehaviorSubject, from} from 'rxjs';
import { environment } from '../../environments/environment';
import { Account } from './account.model';
import { AuthJwtService } from 'src/app/core/auth-jwt.service';
import { map } from 'rxjs/operators';
import { SharedService } from '../shared/services/shared.service';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {
  url = environment.api_url;

  private userIdentity: any;
  private authenticated = false;
  private authenticationState = new Subject<any>();

  constructor(
    private http: HttpClient,
    private auth: AuthJwtService,
    private sharedService: SharedService
  ) { }

  getProfile(): Observable<HttpResponse<any>> {
    return this.http.get<any>(this.url + 'account/getAccountInfo', { observe: 'response' })
    .pipe(map(res => {
      this.sharedService.changeMessage(res.body);
      return res;
    }));
  }
}
