import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { ReactiveFormsModule } from '@angular/forms';
import { SliderModule } from 'primeng/slider';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { MultiSelectModule } from 'primeng/multiselect';
import { AccordionModule } from 'primeng/accordion';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { ChipsModule } from 'primeng/chips';
import { BlockUIModule } from 'primeng/blockui';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputMaskModule } from 'primeng/inputmask';
import { PasswordModule } from 'primeng/password';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { GoogleChartsModule } from 'angular-google-charts';

@NgModule({
  imports: [
    CommonModule,
    CardModule,
    InputTextModule,
    CheckboxModule,
    ReactiveFormsModule,
    SliderModule,
    RadioButtonModule,
    SelectButtonModule,
    MultiSelectModule,
    AccordionModule,
    DropdownModule,
    InputTextareaModule,
    ToastModule,
    DialogModule,
    ButtonModule,
    ChipsModule,
    BlockUIModule,
    OverlayPanelModule,
    TooltipModule,
    InputSwitchModule,
    InputMaskModule,
    PasswordModule,
    ConfirmDialogModule,
    GoogleChartsModule
  ],
  exports: [
    CardModule,
    InputTextModule,
    CheckboxModule,
    ReactiveFormsModule,
    SliderModule,
    RadioButtonModule,
    SelectButtonModule,
    MultiSelectModule,
    AccordionModule,
    DropdownModule,
    InputTextareaModule,
    ToastModule,
    DialogModule,
    ButtonModule,
    ChipsModule,
    BlockUIModule,
    OverlayPanelModule,
    TooltipModule,
    InputSwitchModule,
    InputMaskModule,
    PasswordModule,
    GoogleChartsModule
  ],
  providers: [
    MessageService, ConfirmationService
  ]
})
export class CommonModules { }
