import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RouteGuardService } from './core/route-guard.service';
import { UnauthorizedPageComponent } from './unauthorized-page/unauthorized-page.component';
import { MainComponent } from './Layout/main/main.component';

const routes: Routes = [

  {
    path: '', loadChildren: () => import('./Account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'admin',
    component: MainComponent,
    children: [
      {
        path: '', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
        canActivate: [RouteGuardService],
        data: {
          authorities: ['ADMIN','MARKETING','SUPPORT_ADMIN','SUPPORT_TEAM','SALES_TEAM']
        }
      },
    ]
  }, 
  {
    path: 'setting',
    component: MainComponent,
    children: [
      {
        path: '', loadChildren: () => import('./Setting/setting.module').then(m => m.SettingModule),
        // canActivate: [RouteGuardService],
        data: {
          authorities: ['ADMIN','SALES']
        }
      },
    ]
  }, 
  {
    path: 'advisor',
    component: MainComponent,
    children: [
      {
        path: '', loadChildren: () => import('./agent/agent.module').then(m => m.AgentModule),
        canActivate: [RouteGuardService],
        data: {
          authorities: ['ADVISOR']
        }
      },
    ]
  },{
    path: 'unauthorized', component: UnauthorizedPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
