import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UserInfoService } from './user-info.service';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class Principal {
    private userIdentity: any;
    private authenticated = false;
    private authenticationState = new Subject<any>();

    constructor(private account: UserInfoService, private route: Router ) { }

    authenticate(identity) {
        this.userIdentity = identity;
        this.authenticated = identity !== null;
        this.authenticationState.next(this.userIdentity);
    }

    hasAnyAuthority(authorities: string[]): Promise<boolean> {
        return Promise.resolve(this.hasAnyAuthorityDirect(authorities));
    }

    hasAnyAuthorityDirect(authorities: string[]): boolean {               
        if (!this.authenticated || !this.userIdentity || !this.userIdentity.userRole) {
            return false;
        }
        for (let i = 0; i < authorities.length; i++) {
            if (authorities[i] == this.userIdentity.userRole) {
                // if (this.userIdentity.authorities.includes(authorities[i])) {
                return true;
            }
        }
        if (this.userIdentity.userRole == 'ADMIN' || this.userIdentity.userRole == 'SALES') {
            this.route.navigate(['/admin']);
            return true;
        } else if (this.userIdentity.userRole == 'AGENT') {
            this.route.navigate(['/agent']);
            return true;
        }
        this.route.navigate(['/unauthorized']);
        return false;
    }

    hasAuthority(authority: string): Promise<boolean> {
        if (!this.authenticated) {
            return Promise.resolve(false);
        }

        return this.identity().then(
            id => {
                return Promise.resolve(id.authorities && id.authorities.includes(authority));
            },
            () => {
                return Promise.resolve(false);
            }
        );
    }

    identity(force?: boolean): Promise<any> {
        if (force === true) {
            this.userIdentity = undefined;
        }

        // check and see if we have retrieved the userIdentity data from the server.
        // if we have, reuse it by immediately resolving
        if (this.userIdentity) {
            return Promise.resolve(this.userIdentity);
        }

        // retrieve the userIdentity data from the server, update the identity object, and then resolve.
        return this.account.getProfile().toPromise().then(response => {
            const account = response.body;
            if (account) {
                this.userIdentity = account;
                this.authenticated = true;
            } else {
                this.userIdentity = null;
                this.authenticated = false;
            }
            this.authenticationState.next(this.userIdentity);
            return this.userIdentity;
        })
            .catch(err => {
                this.userIdentity = null;
                this.authenticated = false;
                this.authenticationState.next(this.userIdentity);
                return null;
            });
    }

    isAuthenticated(): boolean {
        return this.authenticated;
    }

    isIdentityResolved(): boolean {
        return this.userIdentity !== undefined;
    }

    getAuthenticationState(): Observable<any> {
        return this.authenticationState.asObservable();
    }

    getImageUrl(): string {
        return this.isIdentityResolved() ? this.userIdentity.imageUrl : null;
    }
}
