import { Component, OnInit,Input,Output,OnChanges,SimpleChanges} from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit,OnChanges {

  isNavSmall: boolean = false;
  activeChild;
  @Input() RootDirectory;
  userRole: any;
  
  constructor(
    private shared : SharedService
  ) { }

  ngOnInit() {
    this.shared.currentMessage.subscribe(res => {
      this.userRole = res['userRole'];
    });
    let url = window.location.href ;
    let str = url.split(/[\\\/]/);
    this.activeChild = str[str.length - 1];
  }
  ngOnChanges(change : SimpleChanges){
  }
  changeChildDirectory(child){
    this.activeChild = child;
  };
}
