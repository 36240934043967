import { Component, OnInit, OnDestroy } from '@angular/core';
import {MenuItem} from 'primeng/api';
import { Router, ActivatedRouteSnapshot , RouterStateSnapshot} from '@angular/router';
import { UserInfoService } from 'src/app/core/user-info.service';
import { AuthJwtService } from 'src/app/core/auth-jwt.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Subscription } from 'rxjs';
import { Principal } from 'src/app/core/principal.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  private subscription: Subscription = new Subscription();

  awsImagePath = environment.aws_imagepath; // Used to store Constant of AWS Image Path33
  size = '30x30/';

  itemsForWeb: MenuItem[];
  itemsForMobile: MenuItem[];

  activeFlag: string;
  avtar: string; // used to show avtarImage of user on header

  userAllData: any;
  userFirstName = '';
  userLastName = '';
  profileid: any;
  blockUI = true;
  userRole;

  constructor(
    private route: Router,
    private user: UserInfoService,
    private auth: AuthJwtService,
    private shared: SharedService,
    private principal: Principal
  ) {
       this.subscription.add(
        this.shared.currentMessage.subscribe(res => {
          this.userRole = res['userRole'];
          if (this.userRole === 'ADMIN') {
            this.blockUI = false;
            this.itemsForWeb = [
              {
                  label: this.userRole,
                  items: [
                    // {
                    //   label: 'Dashboard',
                    //   icon: 'pi pi-fw pi-chart-bar',
                    //   routerLink: ['/admin/dashboard'],
                    // },
                    {
                      label: 'Search',
                      icon: 'pi pi-fw pi-search',
                      routerLink: ['/admin/quicksearch'],
                      items: [
                        {
                          label: 'Quick Search',
                          icon: 'pi pi-fw pi-search',
                          routerLink: ['/admin/quicksearch']
                        },
                        {
                          label: 'Advance Search',
                          icon: 'pi pi-fw pi-filter',
                          routerLink: ['/admin/advancesearch'],
                        }
                      ]
                    },
                    {
                      label: 'Reports',
                      icon: 'pi pi-fw pi-file',
                      routerLink: ['/admin/searchuser'],
                      items: [
                        {
                          label: 'User Report',
                          icon: 'pi pi-fw pi-user',
                          routerLink: ['/admin/searchuser'],
                        }, 
                        {
                          label: 'Subscription Report',
                          icon: 'pi pi-fw pi-money-bill',
                          routerLink: ['/admin/paymentreport'],
                        },
                        {
                          label: 'Boost Report',
                          icon: 'pi pi-pw pi-bookmark',
                          routerLink: ['/admin/boostreport'],
                        },
                        {
                          label: 'Attrition Report',
                          icon: 'pi pi-pw pi-circle-on',
                          routerLink: ['/admin/attrition'],
                        },
                      ]
                    },                   
                    {
                      label: 'Personalized Services',
                      icon: 'pi pi-fw pi-star',
                      routerLink: ['/admin/personalizeduser'],
                    },
                    {
                      label: 'Subscriptions-Promocodes',
                      icon: 'pi pi-fw pi-dollar',
                      routerLink: ['/admin/planpromocode/reportplanpromocode'],
                    },
                    {
                      label: 'User Management',
                      icon: 'pi pi-fw pi-users',
                      routerLink: ['/admin/usermanagement'],
                    }, 
                    {
                      label: 'Settings',
                      icon: 'pi pi-fw pi-cog',
                      routerLink:['/setting'],
                    },
                    {label: 'Logout', icon: 'pi pi-fw pi-sign-out', command: (click) => { this.logout(); }}
                  ]
              }
            ];
          } else if(this.userRole === 'SALES'){
            this.blockUI = false;
            this.itemsForWeb = [
              {
                  label: this.userRole,
                  items: [
                    // {
                    //   label: 'Dashboard',
                    //   icon: 'pi pi-fw pi-chart-bar',
                    //   routerLink: ['/admin/dashboard'],
                    // },
                    {
                      label: 'Search',
                      icon: 'pi pi-fw pi-search',
                      routerLink: ['/admin/searchuser'],
                    },
                    // {
                    //   label: 'Subscription Report',
                    //   icon: 'pi pi-fw pi-money-bill',
                    //   routerLink: ['/admin/paymentreport'],
                    // },
                    {
                      label: 'Settings',
                      icon: 'pi pi-fw pi-cog',
                      routerLink:['/setting'],
                    },
                    {label: 'Logout', icon: 'pi pi-fw pi-sign-out', command: (click) => { this.logout(); }}
                  ]
              }
            ];
          } else {
            this.blockUI = false;
            // this.itemsForWeb = [
            //   {
            //       label: 'Agent',
            //       items: [
            //         {
            //           label: 'Dashboard',
            //           icon: 'pi pi-fw pi-chart-bar',
            //           routerLink:['/agent/dashboard'],
            //         },
            //         {
            //           label: 'Add Profile',
            //           icon: 'pi pi-fw pi-money-bill',
            //           routerLink:['/agent/addprofile'],
            //         },
            //         {
            //           label: 'Settings',
            //           icon: 'pi pi-fw pi-cog',
            //           routerLink:['/setting'],
            //         },
            //         {label: 'Logout', icon: 'pi pi-fw pi-sign-out',command: (click) => { this.logout(); }}
            //       ]
            //   }
            // ];
          }
        })
      );
   }

  ngOnInit() {
    this.shared.currentRouteInHeader.subscribe(res => {
      this.activeFlag = res;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  changeRoute(val) {
    if (val == 'personal') {
      this.route.navigate(['/enroll/personal']);
    } else if (val === 'matches') {
      this.route.navigate(['/matches']);
    } else if (val === 'profile') {
      this.route.navigate(['/profile/' + this.profileid]);
    } else {
    }
  }

  logout() {
    this.principal.authenticate(null);
    this.auth.logout();
  }

}
