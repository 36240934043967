import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AuthInterceptor } from '../app/shared/interceptors/AuthInterceptor.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { HeaderComponent } from './Layout/header/header.component';
import { MainComponent } from './Layout/main/main.component';
import { MenubarModule } from 'primeng/menubar';
import { CommonModules } from './Common/common.module';
import { UnauthorizedPageComponent } from './unauthorized-page/unauthorized-page.component';
import { GalleriaModule } from 'primeng/galleria';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { SideNavComponent } from "./Layout/side-nav/side-nav.component";
import { TopNavComponent } from "./Layout/top-nav/top-nav.component";
// import { GoogleChartsModule } from 'angular-google-charts';
import { FormsModule } from '@angular/forms';
// import { ChatComponent } from './Layout/chat/chat.component';

@NgModule({
  declarations: [
    SideNavComponent,
    TopNavComponent,
    AppComponent,
    HeaderComponent,
    MainComponent,
    UnauthorizedPageComponent
    // ChatComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MenubarModule,
    CommonModules,
    GalleriaModule,
    TriStateCheckboxModule,
    FormsModule
    // GoogleChartsModule
  ],
  providers: [
    {provide : HTTP_INTERCEPTORS, useClass : AuthInterceptor, multi : true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
