export const environment = {
  production: true,
  aws_imagepath : 'https://d1qh75y81cy12x.cloudfront.net/fit-in/',
  client_api_url : "http://csapi.sthalmatrimony.com/",
  
  //production server settings
   api_url : 'https://adminapi.sthalmatrimony.com/',
  //  api_url : 'http://localhost:3000/',
   docspdfbasepath : 'https://production.sthalmatrimony.com/shared/fileDownloadNew/'
   
  //staging server settings
  // api_url : 'http://asapi.sthalmatrimony.com/',
  // docspdfbasepath : 'http://asapi.sthalmatrimony.com/shared/fileDownloadNew/'
};


// export const environment = {
//   production: true,
//   api_url : 'https://production.sthalmatrimony.com/',
//   aws_imagepath : 'https://d1qh75y81cy12x.cloudfront.net/fit-in/',
//   docspdfbasepath : 'https://production.sthalmatrimony.com/shared/fileDownloadNew/'
//  };