import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserInfoService } from './user-info.service';
import { Observable } from 'rxjs';
import { Principal } from './principal.service';

@Injectable({
  providedIn: 'root'
})
export class RouteGuardService {

  constructor(
    private router: Router,
    private userInfoService: UserInfoService,
    private principal: Principal,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
    const authorities = route.data['authorities'];
    return this.checkLogin(authorities, state.url);
  }

  checkLogin(authorities: string[], url: string): Promise<boolean> {
    const principal = this.principal;
    return Promise.resolve(
      principal.identity().then(account => {
        // access to all, if authoroties not provided to route   
        if (!authorities || authorities.length === 0) {
          return true;
         }
      if (account) {
          return principal.hasAnyAuthority(authorities).then(response => {
            if (response) {
              return true;
            }
            return false;
          });
      }
        this.router.navigate(['']);
        return false;
      })
    );
  }
}
