import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthJwtService } from '../../core/auth-jwt.service';
import { Router } from '@angular/router';
import { log } from 'util';
import { environment } from 'src/environments/environment';


@Injectable({ providedIn: 'root' })

export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private authJwtService: AuthJwtService,
        private router: Router
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token;
        token = this.authJwtService.getToken();
        if (token === undefined || token === '') {     
            if (req.url === environment.api_url + 'account/login' ||
                req.url === environment.api_url + 'account/sendOtp' ||
                req.url === environment.api_url + 'account/register' ||
                req.url === environment.api_url + 'account/otpVerification' ||
                req.url === environment.api_url + 'account/addPassword' ||
                req.url === environment.api_url + 'account/activateProfile'  ||
                req.url === environment.api_url + 'profile/getProfileByProfileUniqueIdForPDF' ||
                req.url.split('/').slice(-3)[0] === 'validateEmailVerificationLink' ) {
            } else {
                this.router.navigate(['']);
            }
        } else {
            if (req.url === environment.api_url + 'profile/getProfileByProfileUniqueIdForPDF' ) {
            } else {
                req = req.clone({
                    setHeaders: {
                        Authorization: token
                    }
                });
            }
        }
        return next.handle(req);
    }
}
