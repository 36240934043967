import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { SharedService } from '../shared/services/shared.service';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({ providedIn: 'root' })

export class AuthJwtService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private shared: SharedService,
  ) { }

  url = environment.api_url;

  key = 'sthal';

  login(obj): Observable<any> {
    const param = {
      'username' : '91' + obj.username,
      'password' : obj.password
    };
    return this.http.post<any>(this.url + 'account/login', param, httpOptions);
  }

  setToken(token) {
    localStorage.setItem(this.key, token);
  }

  getToken() {
    if (localStorage.getItem(this.key) === null || localStorage.getItem(this.key) == undefined || localStorage.getItem(this.key) == '') {
      return '';
    } else {
      return localStorage.getItem(this.key);
    }
  }

  logout() {
    localStorage.clear();
    this.shared.changeProfileData('');
    this.shared.changeMessage('');
    this.shared.changeImageData('');
    this.router.navigate(['/']);
  }


}
