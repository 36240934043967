import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private messageSource = new BehaviorSubject('');
  currentMessage = this.messageSource.asObservable(); //account info source

  private profileDataSource = new BehaviorSubject('');
  profileData = this.profileDataSource.asObservable();

  private countryDataSource = new BehaviorSubject('');
  countryData = this.countryDataSource.asObservable();

  private imageDataSource = new BehaviorSubject('');
  imageData = this.imageDataSource.asObservable();

  private currentAdminTabSource = new BehaviorSubject('');
  currentAdminTab$ = this.currentAdminTabSource.asObservable();

  private currentRouteInHeaderSource = new BehaviorSubject('');
  currentRouteInHeader = this.currentRouteInHeaderSource.asObservable();

  constructor() { }

  changeMessage(message: any) {
    this.messageSource.next(message);
  }
  changeProfileData(obj: any) {
    this.profileDataSource.next(obj);
  }
  changeCountryData(obj: any) {
    this.countryDataSource.next(obj);
  }
  changeImageData(obj: any) {
    this.imageDataSource.next(obj);
  }
  changeCurrentAdminTab(activetabName: string) {
    this.currentAdminTabSource.next(activetabName);
  }
  changeCurrentRouteInHeader(activeRouteName: string) {
    this.currentRouteInHeaderSource.next(activeRouteName);
  }
}
